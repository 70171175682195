
  import Header from '@/components/core/Header';
   import BackHeader from '@/components/core/BackHeader';
  import Footer from '@/components/core/Footer';
 // import Interlinks from '@/components/core/Interlinks';
  export default {
    name: "HomeLayout",
    components: {
      Header,
      BackHeader,
      Footer,
      //Interlinks
    }, props:{
      traslucentWhite:{
        type: Boolean,
        default: false
      },
      head() {
    return {
      meta: [
        { hid: 'og:url',
        property: 'og:url',
        content: this.$config.siteUrl + this.$route.path
      },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://www.goa.app' + this.$route.path
        }
      ]
    }
  },
    }
  }

